import { useStore } from '@nanostores/react'
import { useState } from 'react'
import { fauxtoshopStore } from '../../lib/state/fauxtoshop-store'
import '../../styles/form.scss'
import Telemetry from '../../lib/telemetry-frontend'

const HiddenContent = () => {
  const { postBreakClicks: clicks } = useStore(fauxtoshopStore)
  const [lifetimeYear, setLifetimeYears] = useState<string>('40')
  const [hasSubmit, setHasSubmit] = useState(false)
  const [hasResult, setHasResult] = useState(false)

  return (() => {
    switch (true) {
      case hasResult:
        return (
          <p>
            Oof, bad news. Your plan is a bit… optimisitic. But, since
            GuideGuide is likely to outlive you, honestly, the annual price
            seems like a better choice. Rather than going through the
            complicated process of writing me into your will, why don’t you just{' '}
            <a
              href="/pricing"
              tabIndex={-1}
              onClick={() => {
                window.fathom?.trackGoal('JIIUFBN4', 0)
                Telemetry.trackEvent('demo.last-hidden-signup')
              }}
            >
              sign up now
            </a>
            ?
          </p>
        )

      case hasSubmit:
        return (
          <p className="loading">
            Hold on while I paste your answer into latest trending AI site that
            people think is going to take their jobs
          </p>
        )
      case clicks === 0:
        return (
          <>
            <p>
              What the ever-living &*@%!? I told you to leave that alone. I
              don’t walk into <em>your</em> design tools and start clicking
              things until <em>your</em> work falls apart. You owe me a new
              window. Why don’t you just{' '}
              <a
                href="/pricing"
                tabIndex={-1}
                onClick={() => {
                  window.fathom?.trackGoal('YKKKQEQV', 0)
                  Telemetry.trackEvent('demo.first-hidden-signup')
                }}
              >
                sign up
              </a>{' '}
              and we'll call it even.
            </p>
            <p>
              <small>Jerk…</small>
            </p>
          </>
        )
      case clicks < 2:
        return (
          <p>
            Keep clicking, buddy. It ain’t going to work now that you broke it.
          </p>
        )
      case clicks < 3:
        return <p>What are you trying to prove?</p>
      case clicks < 4:
        return <p>This is just sad…</p>
      case clicks < 5:
        return (
          <p>
            Now whenever you click, GuideGuide creates a guide, lines it up all
            nice, and then just throws it into the trash. That can’t be good for
            the environment.
          </p>
        )
      case clicks < 6:
        return (
          <p>
            Please — keep clicking. Hurdle yourself toward a repetitive strain
            injury. Then instead of complaining about subscription fatigue, you
            can whinge about how your new ergonomic keyboard makes you look like
            a doofus on a dork throne.
          </p>
        )
      default:
        return (
          <>
            <p>
              Congratulations, you’ve found the hidden lifetime license! To make
              sure your lifetime license is properly tailored to you, I need to
              collect some very personal information.
            </p>
            <form
              className="form"
              onSubmit={event => {
                event.preventDefault()
                setHasSubmit(true)
                setTimeout(() => {
                  setHasResult(true)
                }, 5000)
              }}
            >
              <div className="form-row">
                <label htmlFor="years-left">
                  How many more years you plan to live?
                </label>
                <input
                  type="number"
                  id="years-left"
                  value={lifetimeYear}
                  step="5"
                  min="0"
                  onChange={event => {
                    setLifetimeYears(event.currentTarget.value)
                  }}
                />
              </div>
              <div className="form-row">
                <button
                  type="submit"
                  className="btn outline"
                  style={{ height: '100%' }}
                >
                  Get my license
                </button>
              </div>
            </form>
          </>
        )
    }
  })()
}

export default HiddenContent
