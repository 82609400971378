import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import '../../styles/web-guideguide/fauxtoshop.scss'
import { fauxtoshopStore } from '../../lib/state/fauxtoshop-store'
import { CSSTransition } from 'react-transition-group'
import HiddenContent from '@guideguide/website/src/components/web-guideguide/hidden-content'
import { useStore } from '@nanostores/react'
import getBoundingClientRect from '@guideguide/website/src/lib/getBoundingClientRect'
import {
  setCanvas,
  demoStore,
  stopDemo,
  startDemo,
} from '@guideguide/website/src/lib/demo/scripted-demo'
import Telemetry from '../../lib/telemetry-frontend'

const Fauxtoshop = (props: PropsWithChildren<{}>) => {
  const [isBroken, setIsBroken] = useState(false)
  const [closeButtonClicks, setCloseButtonClicks] = useState(0)
  const animationRef = useRef<HTMLDivElement>(null)
  const fauxtoshop = useStore(fauxtoshopStore)
  const demo = useStore(demoStore)

  useEffect(() => {
    if (closeButtonClicks > 0) {
      Telemetry.trackEvent('demo.click-close-button', {
        quantity: closeButtonClicks,
      })
    }
    switch (closeButtonClicks) {
      case 1:
        alert(
          "Listen, I didn't put a lot of time into this button, so please just leave it alone."
        )
        break
      case 2:
        window.fathom?.trackGoal('8NAX85HW', 0)
        Telemetry.trackEvent('demo.break-demo')
        stopDemo()

        setIsBroken(true)

        fauxtoshopStore.set({
          ...fauxtoshopStore.get(),
          isBroken: true,
        })

      default:
      // noop
    }
  }, [closeButtonClicks])

  useEffect(() => {
    ;(async () => {
      const canvas = document.querySelector<HTMLElement>(
        '.hero-with-guideguide .canvas'
      )
      if (!canvas) throw new Error('Canvas not available')
      setCanvas(await getBoundingClientRect(canvas))
    })()
  }, [])

  return (
    <div className="simulated-design-app-wrapper">
      <CSSTransition nodeRef={animationRef} in={isBroken} timeout={1500}>
        <div className="simulated-design-app" ref={animationRef}>
          <div className="header">
            <button
              aria-hidden="true"
              className="close-button"
              tabIndex={-1}
              onClick={() => {
                setCloseButtonClicks(closeButtonClicks + 1)
              }}
            >
              <span />
            </button>
          </div>
          <div className="canvas">{props.children}</div>
          <div className="footer" aria-hidden="true">
            <div className="app-name">Fauxtoshop</div>
            <div className="explanation">
              {demo.isPlaying ? (
                'GuideGuide is in auto mode but you can interact with it!'
              ) : (
                <>
                  The GuideGuide demo is paused.{' '}
                  <button
                    onClick={startDemo}
                    tabIndex={-1}
                    className="btn link"
                  >
                    Start it!
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
      {fauxtoshop.isBroken && (
        <div className="hidden-content" aria-hidden="true">
          <div>
            <HiddenContent />
          </div>
        </div>
      )}
    </div>
  )
}

export default Fauxtoshop
